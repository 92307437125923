import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      heading: "Play PC Games on Any Device",
      subheading:
        "No gaming PC needed – play your favourite, games on phone, tablet or laptop",
      play_now: "Play Now",
      why_gameaway: "Why Choose GameAway",
      your_device_your_games: "Your device. Your games.",
      our_power: "Our power.",
      experience_pc_gaming:
        "Experience PC gaming without expensive hardware. Over 50,000 players already do.",
      works_basic_devices: "Works on Basic Devices",
      play_high_end_games:
        "Play high-end games on your regular phone or laptop",
      low_internet_usage: "Low Internet Usage",
      optimised_stable_gaming: "Optimised for stable gaming on normal internet",
      pay_when_play: "Pay Only When You Play",
      no_upfront_costs: "No big upfront costs - buy hours as needed",
      signup: "Sign Up",
      sign_up_below: "Enter your email below to create your account.",
      check_your_email: "Check your email",
      we_sent_verification: "We sent a verification code to",
      verify_email_address: "Verify email address",
      didnt_receive_email: "Didn't receive our email?",
      back_to_login: "Back to Login",
      how_to_start: "How to Start",
      cloud_local_saves: "Cloud + Local saves",
      add_playtime_anytime: "Add more playtime anytime",
      install_your_games: "Install your own games",
      need_custom_plan: "Need a custom plan?",
      select_pc_option: "Select one of the PC options below",
      choose_how_many_hours: "Choose how many hours you want to play",
      customizable_storage_space: "Customizable storage space",
      shareable_with_friends: "Shareable with friends",
      products: "Products",
      virtual_gaming_pc: "Virtual Gaming PC",
      virtual_pc_description: "Your own dedicated PC of 100 or 200 GB",
      virtual_gaming_pc_install:
        "Install any game or software you own from Steam, Epic or other stores",
      virtual_gaming_pc_rent:
        "Rent PC for 1 week or 1 month,<strong>&nbsp;add hours of playtime as needed&nbsp;</strong>",
      play_pre_installed_games:
        "Play any game you<strong>&nbsp;already own&nbsp;</strong>from our list of&nbsp;<a style='text-decoration: underline; cursor: pointer;' href='/gamelist'>pre-installed games</a>&nbsp;",
      virtual_gaming_pc_servers: "Servers in India, Middle East and SE Asia",
      virtual_gaming_pc_share:
        "Share your PC, with full support for offline saves",
      pre_installed_games: "Pre-installed Games",
      pre_installed_games_shared_vms:
        "Pre-installed Games/Shared Virtual Gaming PCs",
      pre_installed_games_buy: "Buy as many hours as you need",
      pre_installed_games_data: "Your data is not stored between sessions",
      pre_installed_games_cloud: "Only cloud saves are stored for games",
      pre_installed_games_server: "Servers only in India",
      see_pricing: "See Pricing",
      how_it_works: "How it Works",
      three_simple_steps: "Three Simple Steps",
      effortless_setup: "Effortless setup for smooth, instant gameplay",
      how_it_works_1: "Pick Your Game",
      how_it_works_2: "Choose from pre-installed games or use ones you own",
      how_it_works_3: "Connect to Nearby Servers",
      how_it_works_4: "Our India servers give you a smooth gameplay",
      how_it_works_5: "Start Playing",
      how_it_works_6:
        "Jump right in - when you return, your game progress is saved with us",
      works_any_device: "Works on any device",
      what_our_users_think: "What Our Users Think",
      home: "Home",
      game_list: "Game List",
      pricing: "Pricing",
      faqs: "FAQs",
      profile: "Profile",
      sign_in: "Log In",
      sign_out: "Sign Out",
      virtual_gaming_pc_pricing_1:
        "Dedicated, shareable high-performance machines for you to install games, mods and other software.",
      virtual_gaming_pc_pricing_2:
        "We will preserve all your data for the time period you choose.",
      virtual_gaming_pc_pricing_3:
        "Not required for playing games from our Game List.",
      virtual_gaming_pc_pricing_4:
        "Contact us to upgrade from 100 GB to 200 GB.",
      virtual_gaming_pc_pricing_5:
        "Playtime must be purchased separately, this is not an unlimited plan.",
      one_week: "1 Week",
      one_month: "1 Month",
      subscribe_and_save: "Subscribe & Save 5%",
      per: "per",
      week: "week",
      pmonth: "month",
      card: "Card",
      upi: "UPI",
      add_playtime: "Add Playtime",
      play_your_own_games: "Play your own games with full PC access",
      choose_from_preinstalled:
        "Choose from our pre-installed games or a friend's shared PC",
      pre_installed_and_shared:
        "Pre-installed games + your friend's shared PCs",
      cloud_saves_only: "Cloud saves only",
      playtime_pricing_1:
        "Playtime for our pre-installed games from our Game List or for your virtual gaming PC.",
      playtime_pricing_2:
        "You must own the games you want to play on the supported store.",
      subscribe_hours: "Subscribe to {{hours}} hours per {{period}}",
      buy_hours: "Buy {{hours}} hours",
      subscribe_vm:
        "Subscribe to Virtual Gaming PC + {{vmHours}} hour(s) per {{vmPaymentPeriod}}",
      buy_vm: "Buy Virtual Gaming PC + {{vmHours}} hour(s)",
      vm_playtime_disclaimer:
        "You must buy playtime to access the Virtual Gaming PC",
      weekly: "Weekly",
      monthly: "Monthly",
      username: "Username",
      password: "Password",
      dont_have_account: "Don't have an account?",
      sign_up_here: "Sign up here",
      forgot_password: "Forgot your password?",
      email: "Email",
      phone: "Phone Number",
      already_have_account: "Already have an account?",
      sign_in_here: "Sign in here",
      enter_username: "Enter your username below to login to your account.",
      reset_password: "Reset Password",
      set_new_password: "Set new password",
      different_to_previous_passwords:
        "Your new password must be different to previously used passwords.",
      successfully_changed_password:
        "You successfully changed your password. Click below to start playing.",
      enter_code: "Enter your code",
      new_password: "New Password",
      resend_code: "Click to resend",
      confirm_account: "Confirm Account",
      give_playtime: "Give playtime, get playtime! 🎉",
      refer_a_friend:
        "Refer a friend using the link. If they purchase a Virtual Gaming PC plan, you <strong>both get 5 free hours of playtime (₹262.5 value).</strong>",
      copy_referral_link: "Copy Referral Link",
      link_copied: "Link Copied!",
      referral_text:
        "Hey! Get 5 free hours on GameAway to play any PC game you own by using my referral link: {{referralLink}}",
      referred_signed_out:
        "You've been referred! Sign up and buy a Virtual Gaming PC Plan to receive 5 free hours of playtime 🎉",
      referred_signed_in:
        "You've been referred! Buy a Virtual Gaming PC Plan to receive 5 free hours of playtime 🎉",
      sign_up_to_referral: "Sign Up to Refer",
      virtual_gaming_pc_active: "Virtual Gaming PC Active",
      virtual_gaming_pc_inactive: "Virtual Gaming PC Inactive",
      virtual_gaming_pc_buy_now: "Buy now to install your own games",
      remaining: "available playtime",
      copied: "Copied!",
      set_pin: "Set pin",
      not_set: "Not set",
      state: "State:",
      select_game: "Select Game",
      start_game: "Start {{game}}",
      stop_game: "Stop {{game}}",
      add_more_hours: "Add More Hours",
      client_loading: "Loading",
      not_running: "Not running",
      running: "Running",
      booting: "Booting",
      how_to_start_playing: "How to Start Playing",
      follow_the_steps: "Follow the steps below to get started",
      instruction_moonlight:
        "Moonlight is an open-source game streaming client that gives you access to our computers.",
      instruction_power_on_1:
        "Choose the Private Gaming PC or a game from Quick Play PC and click Power On. When ready, you'll see an IP address.",
      instruction_power_on_2: "Copy the IP address",
      instruction_go_to_moonlight_1: "Open Moonlight, click the",
      instruction_go_to_moonlight_2:
        "button on the top right and add the IP address you just copied. * You’ll have to do this every time you start a game. * For iOS, your IP should be prefixed by “::ffff:” and enclosed by square brackets “[]”. It should look like this: ",
      instruction_set_pin_1:
        "The first time you play, Moonlight will give you a PIN. Return to this page, enter it into the PIN field and click",
      instruction_set_pin_2: "Set PIN.",
      instruction_back_to_moonlight:
        "On Moonlight, once the lock symbol disappears from the computer, click on it and run Desktop. You can tap the screen with 3 fingers on mobile to bring up the keyboard if needed.",
      instruction_shut_down:
        "When you're done playing, click Stop Game here, or shut down our PC. If playing a pre-installed game, before shutting down the remote PC, quit your game and check that cloud saves are uploaded to Steam. It will look like the screenshot below.",
      instruction_select_game:
        "Select a game that <strong>you own</strong> or a free-to-play game from the list of games in the dropdown. You will have to sign in to Steam or Epic later to validate ownership.<br/>To install your own games, select Virtual Gaming PC.",
      instruction_click_start_game:
        "Click on the 'Start Game' button - after a brief wait, the State will change to 'Running' and you'll see an IP address to connect to your game.",
      instruction_open_moonlight:
        "Open Moonlight and click the + button on the top right. You have to do this every time you start a game.",
      instruction_copy_ip_address:
        "Copy the IP address from step 2 and enter it into the field on Moonlight. On iOS, prefix the IP with ::ffff: and surround with []. For instance, if your IP is 1.2.3.4, enter [::ffff:1.2.3.4]",
      instruction_enter_pin:
        "The first time you play, Moonlight will give you a PIN, enter it into the PIN field above and click Set PIN.",
      instruction_set_video_codec:
        "In Moonlight settings, set your video codec to HEVC (if supported by your device). You can also adjust the resolution and FPS to your liking.",
      instruction_run_desktop:
        "On Moonlight once the lock symbol disappears from the field, click on it, and run <strong>Desktop</strong>.",
      instruction_log_in_steam:
        "Log in to Steam and your game will start automatically. If you need to bring up the keyboard on a mobile device or tablet, tap the screen with 3 fingers.",
      instruction_important:
        "IMPORTANT: DO NOT CLOSE ANY RUNNING COMMAND PROMPT SCRIPTS ON THE REMOTE PC",
      instruction_stop_instance:
        "To stop your instance, click the 'Stop Game' button, shutdown the remote PC, or do nothing, and it will automatically shutdown in 15 minutes.",
      instruction_check_cloud_saves:
        "<strong>Important:</strong></string> Before shutting down the remote PC, quit your game and check that cloud saves are uploaded to Steam. It will look like the screenshot below.",
      instruction_use_controllers:
        "You can also use console controllers like an XBOX controller. Have fun!",
      please_wait_storing_data:
        "Please wait a few minutes before launching your Private Gaming PC. We are storing your data.",
      download_moonlight: "Download Moonlight <br/> Pick your client:",
      other_platforms: "Other platforms under Client Downloads",
      here: "here",
      gift_friend_plan: "Purchase for a friend!",
      friend_registered_email: "Enter friend's registered email",
      share_your_pc: "💸 Share your PC",
      add_vm_sharing_users:
        "Add users who can access your Virtual Gaming PC and share your games, with full support for offline saves 💸",
      // Add other English translations here
    },
  },
  hi: {
    translation: {
      heading: "आपके सभी डिवाइस पर क्लाउड गेमिंग",
      subheading:
        "क्लाउड में शक्तिशाली कंप्यूटरों का उपयोग करें और <i>AAA गेम्स इंस्टॉल करें या खेलें</i>, जिसमें <i>अत्यंत कम लेटेंसी</i> हो।",
      signup: "साइन अप",
      products: "उत्पादों",
      virtual_gaming_pc: "वर्चुअल गेमिंग पीसी",
      virtual_pc_description: "आपका खुद का समर्पित पीसी 100 GB या 200 GB का",
      virtual_gaming_pc_install:
        "Steam, Epic या अन्य स्टोर्स से आपके द्वारा खरीदी गई कोई भी गेम या सॉफ्टवेयर इंस्टॉल करें",
      virtual_gaming_pc_rent:
        "पीसी को 1 सप्ताह या 1 महीने के लिए किराए पर लें,<strong> &nbsp;आवश्यकतानुसार खेलने के घंटे बढ़ाएँ&nbsp; </strong>",
      play_pre_installed_games:
        "हमारी &nbsp;<a style='text-decoration: underline; cursor: pointer;' href='/gamelist'>पूर्व-स्थापित गेम्स</a>&nbsp;की सूची में से कोई भी गेम खेलें जो आप<strong>&nbsp;पहले से खरीद चुके हैं&nbsp;</strong>",
      virtual_gaming_pc_servers:
        "भारत, मध्य पूर्व और दक्षिण पूर्व एशिया में सर्वर",
      virtual_gaming_pc_share:
        "ऑफ़लाइन सेव के लिए पूर्ण समर्थन के साथ, अपना पीसी साझा करें",
      pre_installed_games: "पूर्व-स्थापित गेम्स",
      pre_installed_games_shared_vms:
        "पूर्व-स्थापित गेम्स/सांझा वर्चुअल गेमिंग पीसी",
      pre_installed_games_buy: "आवश्यकतानुसार घंटे खरीदें",
      pre_installed_games_data:
        "आपका डेटा सत्रों के बीच में संग्रहित नहीं किया जाता",
      pre_installed_games_cloud: "केवल क्लाउड सेव्स संग्रहित किए जाते हैं",
      pre_installed_games_server: "सर्वर केवल भारत में",
      see_pricing: "कीमतें देखें",
      how_it_works: "कैसे काम करता है?",
      how_it_works_1:
        "हमारे वेब डैशबोर्ड का उपयोग करके अपना समर्पित वर्चुअल गेमिंग पीसी शुरू करें या Steam या Epic पर <strong>पहले से खरीदी गई कोई गेम</strong> चुनें।",
      how_it_works_2:
        "हमारे गेमिंग पीसी को शुरू करें जो आपके नजदीकी शक्तिशाली सर्वरों पर चलता है।",
      how_it_works_3:
        "अपने गेम्स को <a target='_blank' href='https://moonlight-stream.org/'>Moonlight</a> के माध्यम से खेलें और अपने लैपटॉप, टैबलेट या फोन पर कम विलंबता और उत्कृष्ट चित्र गुणवत्ता का आनंद लें!",
      what_our_users_think: "हमारे उपयोगकर्ता क्या सोचते हैं?",
      home: "होम",
      game_list: "गेम सूची",
      pricing: "कीमतें",
      faqs: "सामान्य प्रश्न",
      profile: "प्रोफाइल",
      sign_in: "लॉग इन",
      sign_out: "साइन आउट",
      virtual_gaming_pc_pricing_1:
        "गेम्स, मॉड्स और अन्य सॉफ्टवेयर इंस्टॉल करने के लिए समर्पित उच्च-प्रदर्शन वाली मशीनें।",
      virtual_gaming_pc_pricing_2:
        "आपके द्वारा चुनी गई समय अवधि के लिए हम आपके सभी डेटा संरक्षित रखेंगे।",
      virtual_gaming_pc_pricing_3:
        "हमारी गेम सूची से गेम खेलने के लिए आवश्यक नहीं है।",
      virtual_gaming_pc_pricing_4:
        "100 GB से 200 GB तक अपग्रेड करने के लिए हमसे संपर्क करें।",
      virtual_gaming_pc_pricing_5:
        "प्लेटाइम अलग से खरीदना होगा, यह अनलिमिटेड प्लान नहीं है।",
      one_week: "1 सप्ताह",
      one_month: "1 महीना",
      subscribe_and_save: "सब्सक्राइब करें और 5% बचाएं",
      per: "प्रति",
      week: "सप्ताह",
      month: "महीना",
      card: "कार्ड",
      upi: "UPI",
      add_playtime: "प्लेटाइम खरीदें",
      playtime_pricing_1:
        "हमारी गेम सूची में से पूर्व-स्थापित गेम्स के लिए या आपके वर्चुअल गेमिंग पीसी के लिए प्लेटाइम।",
      playtime_pricing_2:
        "आपको समर्थित स्टोर पर जो गेम्स खेलने हैं, वो आपके पास होने चाहिए।",
      subscribe_hours: "{{hours}} घंटे प्रति {{period}} की सदस्यता लें",
      buy_hours: "{{hours}} घंटे खरीदें",
      subscribe_vm:
        "वर्चुअल गेमिंग पीसी + {{vmHours}} घंटे प्रति {{vmPaymentPeriod}} की सदस्यता लें",
      buy_vm: "वर्चुअल गेमिंग पीसी + {{vmHours}} घंटे खरीदें",
      vm_playtime_disclaimer:
        "आपको वर्चुअल गेमिंग पीसी का उपयोग करने के लिए प्लेटाइम खरीदना होगा।",
      weekly: "साप्ताहिक",
      monthly: "मासिक",
      username: "यूज़रनेम",
      password: "पासवर्ड",
      dont_have_account: "क्या आपका खाता नहीं है?",
      sign_up_here: "यहाँ साइन अप करें",
      forgot_password: "अपना पासवर्ड भूल गए?",
      email: "ईमेल",
      phone: "फोन नंबर",
      already_have_account: "क्या आपका खाता पहले से है?",
      sign_in_here: "यहाँ साइन इन करें",
      enter_username: "अपना यूज़रनेम दर्ज करें",
      reset_password: "पासवर्ड रीसेट करें",
      enter_code: "अपना कोड दर्ज करें",
      new_password: "नया पासवर्ड",
      resend_code: "कोड फिर से भेजें",
      confirm_account: "खाता पुष्टि करें",
      give_playtime: "प्लेटाइम दो, प्लेटाइम पाओ! 🎉",
      refer_a_friend:
        "लिंक का उपयोग करके मित्र को रेफर करें। यदि वे वर्चुअल गेमिंग पीसी प्लान खरीदते हैं, तो आप <strong>दोनों को 5 मुफ्त घंटे का प्लेटाइम मिलता है (₹262.5 मूल्य का).</strong>",
      copy_referral_link: "रेफरल लिंक कॉपी करें",
      link_copied: "लिंक कॉपी किया गया!",
      referral_text:
        "मेरे रेफरल लिंक का उपयोग करके GameAway पर किसी भी पीसी गेम को खेलने के लिए 5 मुफ्त घंटे पाएं: {{referralLink}}",
      referred_signed_out:
        "आपको रेफर किया गया है! साइन अप करें और वर्चुअल गेमिंग पीसी प्लान खरीदें ताकि 5 मुफ्त घंटे का प्लेटाइम प्राप्त कर सकें 🎉",
      referred_signed_in:
        "आपको रेफर किया गया है! वर्चुअल गेमिंग पीसी प्लान खरीदें ताकि 5 मुफ्त घंटे का प्लेटाइम प्राप्त कर सकें 🎉",
      sign_up_to_referral: "साइन अप",
      virtual_gaming_pc_active: "वर्चुअल गेमिंग पीसी सक्रिय",
      virtual_gaming_pc_inactive: "वर्चुअल गेमिंग पीसी निष्क्रिय",
      virtual_gaming_pc_buy_now: "अभी खरीदें और अपने खुद के गेम्स इंस्टॉल करें",
      remaining: "शेष",
      copied: "कॉपी किया गया!",
      set_pin: "पिन सेट करें",
      not_set: "सेट नहीं",
      state: "स्थिति:",
      select_game: "गेम चुनें",
      start_game: "{{game}} शुरू करें",
      stop_game: "{{game}} बंद करें",
      add_more_hours: "अधिक घंटे जोड़ें",
      client_loading: "लोड हो रहा है",
      not_running: "चालू नहीं है",
      running: "चालू है",
      booting: "बूट हो रहा है",
      how_to_start_playing: "खेलना कैसे शुरू करें",
      instruction_select_game:
        "ड्रॉपडाउन से एक गेम चुनें जो <strong>आपका हो</strong> या फ्री-टू-प्ले गेम है। बाद में आपको Steam या Epic में साइन इन करके मालिकाना हक सत्यापित करना होगा।<br/>अपने खुद के गेम्स इंस्टॉल करने के लिए, वर्चुअल गेमिंग पीसी चुनें।",
      instruction_click_start_game:
        "'गेम शुरू करें' बटन पर क्लिक करें - थोड़ी देर के इंतजार के बाद, स्टेट 'चालू है' में बदल जाएगा और आपको अपने गेम से कनेक्ट करने के लिए एक IP पता दिखाई देगा।",
      instruction_open_moonlight:
        "Moonlight खोलें और ऊपर दाईं ओर + बटन पर क्लिक करें। आपको हर बार जब आप गेम शुरू करते हैं यह करना होगा।",
      instruction_copy_ip_address:
        "चरण 2 से IP पता कॉपी करें और उसे Moonlight पर फील्ड में दर्ज करें। iOS पर, IP के आगे ::ffff: जोड़ें और [] से घेरें। उदाहरण के लिए, यदि आपका IP 1.2.3.4 है, तो [::ffff:1.2.3.4] दर्ज करें।",
      instruction_enter_pin:
        "पहली बार जब आप खेलते हैं, Moonlight आपको एक PIN देगा, उसे ऊपर दिए गए PIN फील्ड में दर्ज करें और 'पिन सेट करें' पर क्लिक करें।",
      instruction_set_video_codec:
        "Moonlight सेटिंग्स में, अपना वीडियो कोडेक HEVC में सेट करें (यदि आपके उपकरण द्वारा समर्थित हो)। आप अपनी पसंद के अनुसार रेजोल्यूशन और FPS भी समायोजित कर सकते हैं।",
      instruction_run_desktop:
        "Moonlight पर एक बार जब लॉक सिंबल फील्ड से गायब हो जाता है, उस पर क्लिक करें, और <strong>Desktop</strong> चलाएं।",
      instruction_log_in_steam:
        "Steam में लॉग इन करें और आपका गेम स्वचालित रूप से शुरू हो जाएगा। यदि आपको मोबाइल डिवाइस या टैबलेट पर कीबोर्ड लाने की आवश्यकता है, तो स्क्रीन को तीन उंगलियों से टैप करें।",
      instruction_important:
        "<strong>महत्वपूर्ण: रिमोट पीसी पर कोई भी चल रही कमांड प्रॉम्प्ट स्क्रिप्ट को बंद न करें।</strong>",
      instruction_stop_instance:
        "अपने इंस्टेंस को रोकने के लिए, 'स्टॉप गेम' बटन पर क्लिक करें, रिमोट पीसी को शटडाउन करें, या कुछ न करें, और यह 15 मिनट में स्वचालित रूप से शटडाउन हो जाएगा।",
      instruction_check_cloud_saves:
        "<strong>महत्वपूर्ण:</strong> रिमोट पीसी को शटडाउन करने से पहले, अपने गेम को बंद करें और जांच लें कि क्लाउड सेव्स स्टीम पर अपलोड हो गए हैं। यह नीचे दिए गए स्क्रीनशॉट की तरह दिखाई देगा।",
      instruction_use_controllers:
        "आप कंसोल कंट्रोलर्स जैसे कि एक्सबॉक्स कंट्रोलर का भी उपयोग कर सकते हैं। मज़े करें!",
      please_wait_storing_data:
        "कृपया नया गेम शुरू करने से पहले कुछ मिनट प्रतीक्षा करें। हम आपका डेटा संग्रहीत कर रहे हैं",
      download_moonlight: "Moonlight डाउनलोड करें <br/> अपना क्लाइंट चुनें:",
      other_platforms: "अन्य प्लेटफॉर्म्स Client Downloads के तहत",
      here: "यहाँ",
      gift_friend_plan: "दोस्त को प्लान उपहार दें!",
      friend_registered_email: "मित्र का ईमेल दर्ज करें।",
      share_your_pc: "💸 अपना पीसी साझा करें",
      add_vm_sharing_users:
        "Add users who can access your Virtual Gaming PC and share your games, with full support for offline saves 💸",
      // Add other Hindi translations here
    },
  },
  hing: {
    translation: {
      heading: "Sabhi device pe PC gaming",
      subheading:
        "Gaming PC ka zaroorat nahi – phone, tablet ya laptop pe aapka manpasand game khelo.",
      play_now: "Abhi Khelo",
      why_gameaway: "GameAway Kyun?",
      your_device_your_games: "Aapka device. Aapke games.",
      our_power: "Hamara power.",
      experience_pc_gaming:
        "Mehenga hardware bina PC gaming experience karo. Jaise 50,000+ players already karte hain.",
      works_basic_devices: "Basic Device pe chalta hai",
      play_high_end_games:
        "High-end games aapke sadharan phone ya laptop pe khele",
      low_internet_usage: "Kam data usage",
      optimised_stable_gaming:
        "Sadharan internet par stable gaming ke liye optimised",
      pay_when_play: "Khelne ke samay playtime khareedo",
      no_upfront_costs: "Playtime khareedo aapke man se",
      signup: "Sign Up",
      sign_up_below: "Neeche apna email enter karo sign up karne ke liye.",
      check_your_email: "Email check karo",
      we_sent_verification: "Humne verification code bheja hai to",
      verify_email_address: "Email verify karo",
      didnt_receive_email: "Email nahi mila?",
      back_to_login: "Login wapas jao",
      how_to_start: "Shuru karne liye",
      products: "Products",
      virtual_gaming_pc: "Virtual Gaming PC",
      virtual_pc_description: "Aapka khud ka dedicated PC of 100 or 200 GB",
      virtual_gaming_pc_install:
        "Steam, Epic ya doosre stores se apni koi bhi game ya software install karo",
      virtual_gaming_pc_rent:
        "PC ko 1 hafte ya 1 mahine ke liye rent pe lo,<strong>&nbsp;zarurat ke hisaab se playtime add karo&nbsp;</strong>",
      play_pre_installed_games:
        "Hamari <a style='text-decoration: underline; cursor: pointer;' href='/gamelist'>pre-installed games</a> list mein se koi bhi game jo aap <strong>pehle se own karte ho</strong>, khelo",
      virtual_gaming_pc_servers:
        "Servers India, Middle East aur SE Asia mein hai",
      virtual_gaming_pc_share:
        "Aapka PC share karo, offline save support ke saath",
      pre_installed_games: "Pre-installed Games",
      pre_installed_games_shared_vms:
        "Pre-installed Games/Shared Virtual Gaming PCs",
      pre_installed_games_buy:
        "Zarurat ke hisaab se jitne hours chahiye, khareedo",
      pre_installed_games_data:
        "Aapka data sessions ke beech mein store nahi hota",
      pre_installed_games_cloud: "Sirf cloud saves store kiye jaate hain",
      pre_installed_games_server: "Servers sirf India mein hai",
      see_pricing: "Pricing dekho",
      how_it_works: "How it Works",
      three_simple_steps: "Three Simple Steps",
      effortless_setup: "Asaan Setup, Turant aur Smooth Gameplay ke liye",
      how_it_works_1: "Game Chuno",
      how_it_works_2: "Pre-installed games ya aapke games se chuno",
      how_it_works_3: "Najdiki Servers se Connect Karein",
      how_it_works_4: "Hamare India servers aapko smooth gameplay dete hain",
      how_it_works_5: "Khelna Shuru Karo",
      how_it_works_6:
        "Turant khelna shuru karein - waapas lautne Par, aapka game progress hamare saath safe hai",
      works_any_device: "Koi bhi device pe chalta hai",
      what_our_users_think: "What Our Users Think",
      home: "Home",
      game_list: "Game List",
      pricing: "Pricing",
      faqs: "FAQs",
      profile: "Profile",
      sign_in: "Log In",
      sign_out: "Sign Out",
      virtual_gaming_pc_pricing_1:
        "Dedicated, shareable high-performance machines hai jahan aap games, mods aur doosre software install kar sakte ho.",
      virtual_gaming_pc_pricing_2:
        "Jo time period aap choose karte ho, uske liye hum aapka saara data preserve karenge.",
      virtual_gaming_pc_pricing_3:
        "Hamari Game List se games khelne ke liye yeh required nahi hai.",
      virtual_gaming_pc_pricing_4:
        "100 GB se 200 GB tak upgrade karne ke liye contact karo.",
      virtual_gaming_pc_pricing_5:
        "Playtime alag se purchase karna padega, yeh unlimited plan nahi hai.",
      one_week: "1 Week",
      one_month: "1 Month",
      subscribe_and_save: "Subscribe karo & 5% save karo",
      per: "per",
      week: "week",
      month: "month",
      card: "Card",
      upi: "UPI",
      add_playtime: "Playtime add karo",
      play_your_own_games: "Apne aap ke games khelo, full PC access ke saath",
      choose_from_preinstalled:
        "Hamare pre-installed games ya aapke friend ke shared PC chuniye",
      pre_installed_and_shared: "Pre-installed games + shared PCs",
      cloud_saves_only: "Sirf Cloud saves",
      cloud_local_saves: "Cloud + Local saves",
      add_playtime_anytime: "Kabhi bhi playtime add karo",
      install_your_games: "Aapke games install karo",
      customizable_storage_space: "Customizable storage space",
      shareable_with_friends: "Friends ke saath share karo",
      need_custom_plan: "Custom plan chahiye?",
      select_pc_option: "Neeche se ek PC option chuniye",
      choose_how_many_hours: "Khelne ke ghante chuniye",
      playtime_pricing_1:
        "Hamari Game List ke pre-installed games ke liye ya apne virtual gaming PC ke liye playtime.",
      playtime_pricing_2:
        "Jo games tum supported store pe khelna chahte ho, woh tumhe own karna chahiye.",
      subscribe_hours: "{{hours}} ghante har {{period}} ke liye subscribe karo",
      buy_hours: "{{hours}} ghante khareedo",
      subscribe_vm:
        "Virtual Gaming PC + {{vmHours}} ghante har {{vmPaymentPeriod}} ke liye subscribe karo",
      buy_vm: "Virtual Gaming PC + {{vmHours}} ghante khareedo",
      vm_playtime_disclaimer:
        "Virtual Gaming PC use karne ke liye playtime khareedna zaroori hai",
      weekly: "Weekly",
      monthly: "Monthly",
      username: "Username",
      password: "Password",
      dont_have_account: "Account nahi hai?",
      sign_up_here: "Yahan sign up karo",
      forgot_password: "Password bhool gaye?",
      email: "Email",
      phone: "Phone Number",
      already_have_account: "Pehle se account hai?",
      sign_in_here: "Yahan sign in karo",
      enter_username:
        "Neeche apna username enter karo aapka account log in karne ke liye.",
      reset_password: "Password reset karo",
      set_new_password: "Set new password",
      different_to_previous_passwords:
        "Aapka naya password purana passwords se alag hona chahiye.",
      successfully_changed_password:
        "Aapka password reset hua. Khelna shuru karne ke liye neeche click karo.",
      enter_code: "Apna code enter karo",
      new_password: "Password reset karo",
      resend_code: "Code dobara bhejo",
      confirm_account: "Account confirm karo",
      give_playtime: "Give playtime, get playtime! 🎉",
      refer_a_friend:
        "Link ka upyog karke ek dost ko refer karein. Agar vo Virtual Gaming PC plan kharidte hain, to aap <strong>donon ko 5 free ghante ka playtime milta hai (₹262.5 mulya ka).</strong>",
      copy_referral_link: "Referral Link Copy Karein",
      link_copied: "Link Copied!",
      referral_text:
        "Hey! Mere referral link ka use karke GameAway par kisi bhi PC game ko khelne ke liye 5 free ghante paayein: {{referralLink}}",
      referred_signed_out:
        "Aapko refer kiya gaya hai! Sign up karein aur Virtual Gaming PC Plan khareedein taaki 5 free ghante ka playtime paayein 🎉",
      referred_signed_in:
        "Aapko refer kiya gaya hai! Virtual Gaming PC Plan khareedein taaki 5 free ghante ka playtime paayein 🎉",
      sign_up_to_referral: "Sign Up to Refer",
      virtual_gaming_pc_active: "Virtual Gaming PC Active",
      virtual_gaming_pc_inactive: "Virtual Gaming PC Inactive",
      virtual_gaming_pc_buy_now: "Abhi khareedo aur apne games install karo",
      remaining: "playtime baki",
      copied: "Copy ho gaya!",
      set_pin: "Set Pin",
      not_set: "Not set",
      state: "State:",
      select_game: "Select Game",
      start_game: "Start {{game}}",
      stop_game: "Stop {{game}}",
      add_more_hours: "Buy More Hours",
      client_loading: "Loading",
      not_running: "Not Running",
      running: "Running",
      booting: "Booting ho rahi hai",
      how_to_start_playing: "Kaise khelna shuru karein",
      follow_the_steps: "Neeche ke steps follow kijiye",
      instruction_moonlight:
        "Moonlight ek open-source game streaming client hai jo aapko hamare PC ko access deti hai.",
      instruction_power_on_1:
        "Private Gaming PC ya Quick Play PC se ek game chuno aur Power On dabaiye. Jab PC ready hai, aap IP address dekhenge.",
      instruction_power_on_2: "IP address ko copy karo",
      instruction_go_to_moonlight_1: "Moonlight kholiye, top right pe ",
      instruction_go_to_moonlight_2:
        "button dabaiye aur copy kiya hua IP address ko paste karo. * Aapko har bar ye karna padega. * iOS ke liye, your IP should be prefixed by “::ffff:” and enclosed by square brackets “[]”. Aise dikhega:",
      instruction_set_pin_1:
        "Moonlight aapko PIN dega. Is page pe wapas lautiye aur PIN field mein enter kijiye and click",
      instruction_set_pin_2: "Set PIN.",
      instruction_back_to_moonlight:
        "Moonlight pe, jab lock symbol chala jayega, woh PC pe click kijiye aur Desktop select kijiye. Agar aap mobile device pe hain, aap 3 finger tap se keyboard access kar sakte hain.",
      instruction_shut_down:
        "Aapke gaming session ke baad, is page pe Stop Game button dabaiye. Agar aap pre-installed game khel rahe hain, iske pehle aapka game quit kijiye aur confirm kijiye ki cloud save upload hue hain. Neeche wala screenshot jaise dikhega.",
      instruction_select_game:
        "Dropdown se ek game choose karo jo <strong>tumhara ho</strong> ya ek free-to-play game hai. Baad mein tumhe Steam ya Epic me sign in karna hoga ownership validate karne ke liye.<br/>Apne khud ke games install karne ke liye, Virtual Gaming PC select karo.",
      instruction_click_start_game:
        "'Start Game' button pe click karo - thodi der wait ke baad, State 'Running' mein change ho jayega aur tumhe apne game se connect karne ke liye ek IP address dikhega.",
      instruction_open_moonlight:
        "Moonlight kholo aur top right mein + button pe click karo. Tumhe har baar jab game start karte ho ye karna padega.",
      instruction_copy_ip_address:
        "Step 2 se IP address copy karo aur ise Moonlight ke field mein enter karo. iOS pe, IP ke aage ::ffff: add karo aur [] ke beech mein daalo. Udaaharan ke liye, agar tumhara IP 1.2.3.4 hai, toh enter karo [::ffff:1.2.3.4]",
      instruction_enter_pin:
        "Pehli baar jab tum kheloge, Moonlight tumhe ek PIN dega, ise upar diye gaye PIN field mein daalo aur 'Set PIN' pe click karo.",
      instruction_set_video_codec:
        "Moonlight settings mein, apna video codec HEVC mein set karo (agar tumhara device support karta hai). Tum apni pasand ka resolution aur FPS bhi adjust kar sakte ho.",
      instruction_run_desktop:
        "Moonlight pe ek baar jab lock symbol field se gayab ho jaye, uspe click karo, aur <strong>Desktop</strong> chalao.",
      instruction_log_in_steam:
        "Steam mein log in karo aur tumhara game apne aap shuru ho jayega. Agar tumhe mobile device ya tablet pe keyboard lana hai, to screen ko teen ungliyon se tap karo.",
      instruction_important:
        "<strong>ZAROORI: REMOTE PC PE KOI BHI CHAL RAHI COMMAND PROMPT SCRIPTS KO BAND NA KARO.</strong>",
      instruction_stop_instance:
        "Apne instance ko rokne ke liye, 'Stop Game' button pe click karo, remote PC ko shutdown karo, ya kuch na karo, aur ye 15 minute mein automatically shutdown ho jayega.",
      instruction_check_cloud_saves:
        "<strong>Zaroori:</strong> Remote PC ko shutdown karne se pehle, apne game ko quit karo aur check karo ki cloud saves Steam pe upload ho gaye hain. Ye neeche diye gaye screenshot ki tarah dikhega.",
      instruction_use_controllers:
        "Tum console controllers jaise ki ek Xbox controller ka bhi use kar sakte ho. Maza karo!",
      please_wait_storing_data:
        "Hum aapka data store kar rahe hain. Koi minute baad wapas try karo.",
      download_moonlight: "Moonlight download karo <br/> Apna client chuno:",
      other_platforms: "Dusre platforms Client Downloads ke neeche",
      here: "yaha",
      gift_friend_plan: "Dost ke liye khareedo!",
      friend_registered_email: "Enter friend's registered email",
      share_your_pc: "💸 Share your PC",
      add_vm_sharing_users:
        "Add users who can access your Virtual Gaming PC and share your games, with full support for offline saves 💸",
      // Add other Hinglish translations here
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  detection: {
    order: [
      "querystring",
      "cookie",
      "localStorage",
      "sessionStorage",
      "navigator",
      "htmlTag",
      "path",
      "subdomain",
    ],
    caches: ["localStorage", "cookie"],
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
