import { useState, useEffect } from "react";
import React from "react";
import clsx from "classnames";
import AuthContext from "../AuthContext";
import SignedOutHeader from "../SignedOutHeader/SignedOutHeader";
import Footer from "../Footer/Footer";
import { usePostHog } from "posthog-js/react";
import { Link, useNavigate } from "react-router-dom";
import ReferralBanner from "../ReferralBanner/ReferralBanner";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Check, Loader2 } from "lucide-react";

export default function PricingPage2() {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { t } = useTranslation();
  // pass useState() the initial value

  // machineType can be "ondemand", "100gb", "200gb", "1tb"
  const [paymentPeriod, setPaymentPeriod] = useState("week");
  const [paymentType, setPaymentType] = useState("subscription");
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [checkoutEmail, setCheckoutEmail] = useState(null);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const RAZORPAY_SCRIPT_URL = "https://checkout.razorpay.com/v1/checkout.js";

  const planPriceIds =
    process.env.NODE_ENV === "development"
      ? {
          n00b: {
            week: "price_1QgUC4SAcn2IgTItMsI9aRqP",
            month: "price_1QgUCbSAcn2IgTItC4xetahi",
          },
          Casual: {
            week: "price_1QgUEXSAcn2IgTIt8gh7RwYq",
            month: "price_1QgUFKSAcn2IgTItyqAi0Jwp",
          },
          Pro: {
            week: "price_1QgUGCSAcn2IgTItQXWeK64N",
            month: "price_1QgUGeSAcn2IgTIt9FRPLOZc",
          },
        }
      : {
          n00b: {
            week: "price_1Qhyg2SAcn2IgTItRNTzZHtK",
            month: "price_1Qhyg7SAcn2IgTItEIRdkN59",
          },
          Casual: {
            week: "price_1Qhyg9SAcn2IgTIteaIT8DSs",
            month: "price_1QhygDSAcn2IgTItHCxJ34Dj",
          },
          Pro: {
            week: "price_1QhygGSAcn2IgTItSTNlxpkT",
            month: "price_1QhygJSAcn2IgTItDAtVuMV4",
          },
        };
  const {
    user,
    signOut,
    signIn,
    userEmail,
    fetchUserPlan,
    userPlan,
    country,
    paymentGateway,
    determineCountry,
  } = React.useContext(AuthContext);

  useEffect(() => {
    fetchUserPlan(user?.signInUserSession?.idToken);
    determineCountry();
    loadScript(RAZORPAY_SCRIPT_URL).then((loaded) => {
      if (!loaded) {
        console.log(
          "Failed to load Razorpay SDK. Please check your internet connection and try again."
        );
      }
    });
  }, []);

  const renderBuyButton = function (planName) {
    if (userPlan && userPlan.length > 0 && userPlan[0].purchases) {
      if (
        userPlan[0].plan_name?.startsWith(planName) &&
        userPlan[0].purchases[0].subscription === paymentPeriod
      ) {
        return (
          <Button className="w-full rounded-full" disabled>
            <Check className="w-5 h-5 overflow-hidden"></Check>
            {planName} Selected
          </Button>
        );
      } else {
        return (
          <Button
            className="w-full rounded-full"
            onClick={() =>
              handleBuyClick(
                planName,
                paymentPeriod,
                "subscription",
                1,
                paymentGateway,
                false
              )
            }
          >
            Select {planName}
          </Button>
        );
      }
    }
    return (
      <Button
        className="w-full rounded-full"
        onClick={() =>
          handleBuyClick(
            planName,
            paymentPeriod,
            "subscription",
            1,
            paymentGateway,
            false
          )
        }
      >
        Get Started
      </Button>
    );
  };

  async function handleBuyClick(
    plan,
    paymentPeriod,
    payment_mode,
    quantity,
    payment_gateway,
    isHourlyPlan
  ) {
    if (!user) {
      signIn();
    }
    const priceId = planPriceIds[plan][paymentPeriod];
    posthog?.capture("purchase_plan", {
      priceId,
      payment_mode,
      quantity,
      paymentPeriod,
      paymentType,
      paymentPeriod,
      paymentType,
      userEmail,
    });
    setCheckoutLoading(true);
    let products = [
      {
        priceId: priceId,
        isHourlyPlan: isHourlyPlan,
        adjustableQuantity: false,
        quantity: quantity,
        email: checkoutEmail,
        vmMachineType: null,
        vmPaymentPeriod: null,
        vmPaymentType: null,
        hoursPaymentPeriod: null,
        hoursPaymentType: null,
        planName: plan,
        paymentPeriod: paymentPeriod,
      },
    ];
    await fetch(
      payment_gateway === "stripe"
        ? process.env.NODE_ENV === "development"
          ? "https://1ku3nifl2e.execute-api.ap-south-1.amazonaws.com/default/getStripeCheckout-dev-hello"
          : "https://v1wapno6ch.execute-api.ap-south-1.amazonaws.com/prod/getStripeCheckout-prod-hello"
        : process.env.NODE_ENV === "development"
        ? "https://9mljq6r4e3.execute-api.ap-south-1.amazonaws.com/default/getRazorpayCheckout-dev-hello"
        : "https://d58cycm35h.execute-api.ap-south-1.amazonaws.com/default/getRazorpayCheckout-prod-hello",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
        body: JSON.stringify({
          payment_mode: payment_mode,
          products: products,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.checkout_url) {
          fetchUserPlan(user.signInUserSession.idToken, userEmail);
          window.location.href = data.checkout_url;
        } else if (data.order_id && payment_gateway === "razorpay") {
          const options = {
            key: data.key_id,
            amount: data.amount, // Amount should be in currency subunits
            currency: "INR",
            name: "GameAway",
            order_id: data.order_id,
            handler: function (response) {
              fetchUserPlan(user.signInUserSession.idToken, userEmail);
              navigate("/");
            },
            prefill: {
              email: checkoutEmail,
            },
            theme: {
              color: "#231955",
            },
          };
          const paymentObject = new window.Razorpay(options);
          debugger;
          paymentObject.open();
        } else if (data.subscription_id && payment_gateway === "razorpay") {
          const options = {
            key: data.key_id, // Enter the Key ID generated from the Razorpay Dashboard
            subscription_id: data.subscription_id,
            name: "GameAway",
            handler: function (response) {
              fetchUserPlan(user.signInUserSession.idToken, userEmail);
              navigate("/");
            },
            prefill: {
              email: checkoutEmail,
            },
            theme: {
              color: "#231955",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
        setCheckoutLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setCheckoutLoading(false);
      });
  }

  return (
    <div className="pt-0 max-w-6xl px-6 my-0 mx-auto min-h-screen">
      <SignedOutHeader />
      <input
        className={clsx("w-36 mx-auto", {
          hidden: user?.username !== "utkarshdalal",
        })}
        placeholder="customer email"
        onChange={(ev) => setCheckoutEmail(ev.target.value)}
      />
      <section className="py-6">
        <div className="flex flex-col items-center gap-6">
          <div className="w-full mx-auto flex md:flex-row flex-col justify-between">
            <div className="space-y-2">
              <h1 className="text-3xl font-bold text-foreground">Plans</h1>
              <p className="text-base text-muted-foreground">
                Pick your plan below
              </p>
            </div>

            <Tabs
              defaultValue={paymentPeriod}
              className="mt-4"
              onValueChange={setPaymentPeriod}
            >
              <TabsList className="grid w-full grid-cols-2 h-fit rounded-xl border border-[#161b26] p-1 bg-[#1f242f] bg-opacity-0">
                <TabsTrigger
                  value="week"
                  className="px-3 py-1.5 rounded-lg data-[state=active]:bg-[#1f242f]"
                >
                  Weekly
                </TabsTrigger>
                <TabsTrigger
                  value="month"
                  className="px-3 py-1.5 rounded-lg gap-2 flex data-[state=active]:bg-[#1f242f] lg:justify-between"
                >
                  <div className="text-center text-sm font-medium leading-tight">
                    Monthly
                  </div>
                  <div className="px-2.5 py-0.5 rounded-full border border-[#333741] justify-center items-center gap-2.5 flex">
                    <div className="text-xs font-semibold leading-none">
                      -5%
                    </div>
                  </div>
                </TabsTrigger>
              </TabsList>
            </Tabs>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full">
            {/* Noob Plan */}
            <Card className="rounded-2xl border-secondary">
              <div className="p-6 space-y-6">
                <div className="space-y-2">
                  <h3 className="text-2xl font-semibold">n00b</h3>
                  <p className="text-sm text-muted-foreground">
                    Perfect for getting started
                  </p>
                </div>
                <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
                <div className="flex items-baseline text-foreground">
                  <span className="text-2xl font-semibold">₹</span>
                  <span className="text-4xl font-bold">
                    {paymentPeriod === "week" ? 250 : 250 * 4}
                  </span>
                  <span className="ml-1 text-sm text-muted-foreground">
                    / {paymentPeriod} + 18% GST
                  </span>
                </div>
                <div className="space-y-2">
                  <p className="text-muted-foreground">
                    {paymentPeriod === "week" ? 5 : 21.5} hours playtime
                  </p>
                  <p className="text-foreground">Quick Play PC</p>
                </div>
                {checkoutLoading ? (
                  <Button disabled className="w-full rounded-full">
                    <Loader2 className="animate-spin" />
                    Loading
                  </Button>
                ) : (
                  renderBuyButton("n00b")
                )}
                <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
                <div className="flex gap-4">
                  <Check className="text-neutral-50 w-6 h-6 pl-[6.35px] pr-[5.65px] pt-[7.12px] pb-[6.38px] bg-green-600 rounded-full justify-center items-center flex overflow-hidden"></Check>
                  <span className="text-muted-foreground">
                    {t("pre_installed_and_shared")}
                  </span>
                </div>
                <div className="flex gap-4">
                  <Check className="text-neutral-50 w-6 h-6 pl-[6.35px] pr-[5.65px] pt-[7.12px] pb-[6.38px] bg-green-600 rounded-full justify-center items-center flex overflow-hidden"></Check>
                  <span className="text-muted-foreground">
                    {t("cloud_saves_only")}
                  </span>
                </div>
                <div className="flex gap-4">
                  <Check className="text-neutral-50 w-6 h-6 pl-[6.35px] pr-[5.65px] pt-[7.12px] pb-[6.38px] bg-green-600 rounded-full justify-center items-center flex overflow-hidden"></Check>
                  <span className="text-muted-foreground">
                    {t("add_playtime_anytime")}
                  </span>
                </div>
              </div>
            </Card>

            {/* Contender Plan */}
            <Card className="rounded-2xl border-secondary">
              <div className="p-6 space-y-6">
                <div className="space-y-2">
                  <div className="flex items-center gap-2 relative">
                    <h3 className="text-2xl font-semibold">Casual</h3>
                    <Badge
                      variant="secondary"
                      className="absolute bg-fuchsia-900 text-fuchsia-200 right-0 top-0"
                    >
                      Popular
                    </Badge>
                  </div>
                  <p className="text-sm text-muted-foreground">
                    Most popular for regular gamers
                  </p>
                </div>
                <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
                <div className="flex items-baseline text-foreground">
                  <span className="text-2xl font-semibold">₹</span>
                  <span className="text-4xl font-bold">
                    {paymentPeriod === "week" ? 500 : 500 * 4}
                  </span>
                  <span className="ml-1 text-sm text-muted-foreground">
                    / {paymentPeriod} + 18% GST
                  </span>
                </div>
                <div className="space-y-2">
                  <p className="text-muted-foreground">
                    {paymentPeriod === "week" ? 8 : 34} hours playtime
                  </p>
                  <p className="text-neutral-50 text-base font-normal">
                    Private PC – 100GB
                  </p>
                </div>
                {checkoutLoading ? (
                  <Button disabled className="w-full rounded-full">
                    <Loader2 className="animate-spin" />
                    Loading
                  </Button>
                ) : (
                  renderBuyButton("Casual")
                )}
                <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
                {[
                  t("install_your_games"),
                  "100GB storage space",
                  t("shareable_with_friends"),
                  t("cloud_local_saves"),
                  t("add_playtime_anytime"),
                ].map((feature) => (
                  <div key={feature} className="flex gap-4">
                    <Check className="text-neutral-50 w-6 h-6 pl-[6.35px] pr-[5.65px] pt-[7.12px] pb-[6.38px] bg-green-600 rounded-full justify-center items-center flex overflow-hidden"></Check>
                    <span className="text-muted-foreground">{feature}</span>
                  </div>
                ))}
              </div>
            </Card>

            {/* Champion Plan */}
            <Card className="rounded-2xl border-secondary">
              <div className="p-6 space-y-6">
                <div className="space-y-2">
                  <h3 className="text-2xl font-semibold">Pro</h3>
                  <p className="text-sm text-muted-foreground">
                    Best value for serious gamers
                  </p>
                </div>
                <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
                <div className="flex items-baseline text-foreground">
                  <span className="text-2xl font-semibold">₹</span>
                  <span className="text-4xl font-bold">
                    {paymentPeriod === "week" ? 975 : 975 * 4}
                  </span>
                  <span className="ml-1 text-sm text-muted-foreground">
                    / {paymentPeriod} + 18% GST
                  </span>
                </div>
                <div className="space-y-2">
                  <p className="text-muted-foreground">
                    {paymentPeriod === "week" ? 15 : 64.5} hours playtime
                  </p>
                  <p className="text-foreground">Private PC – 200GB</p>
                </div>
                {checkoutLoading ? (
                  <Button disabled className="w-full rounded-full">
                    <Loader2 className="animate-spin" />
                    Loading
                  </Button>
                ) : (
                  renderBuyButton("Pro")
                )}
                <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
                {[
                  t("install_your_games"),
                  "200GB storage space",
                  t("shareable_with_friends"),
                  t("cloud_local_saves"),
                  t("add_playtime_anytime"),
                ].map((feature) => (
                  <div key={feature} className="flex gap-4">
                    <Check className="text-neutral-50 w-6 h-6 pl-[6.35px] pr-[5.65px] pt-[7.12px] pb-[6.38px] bg-green-600 rounded-full justify-center items-center flex overflow-hidden"></Check>
                    <span className="text-muted-foreground">{feature}</span>
                  </div>
                ))}
              </div>
            </Card>
          </div>
          <div className="px-8 py-2 bg-white/0 rounded-full justify-center items-center gap-2 inline-flex">
            <Link
              to="/custom-pricing"
              className="text-neutral-50 text-lg font-semibold leading-7"
            >
              {t("need_custom_plan")}
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
