import React, { Component } from "react";

import Footer from "../../components/Footer/Footer";
import HowItWorks from "../../components/HowItWorks/HowItWorks";
import Testimonials from "../../components/Testimonials/Testimonials";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader.js";
import { usePostHog } from "posthog-js/react";
import { withTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

class LandingPage extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="pt-0 max-w-6xl px-6 my-0 mx-auto min-h-screen">
        <SignedOutHeader context={this.context} />
        <div>
          <div className="container mx-auto px-0 lg:px-4 pt-16 lg:pt-[96px] flex lg:flex-row flex-col gap-16">
            <div>
              <h1 className="text-neutral-50 text-6xl font-bold tracking-tight pb-[24px] text-center lg:text-left">
                {t("heading")}
              </h1>
              <p className="text-muted-foreground mt-4 lg:pb-[72px] pb-8 text-center lg:text-left">
                {t("subheading")}
              </p>
              <Button
                variant="default"
                className="text-center h-14 px-8 py-2 rounded-full shadow shadow-inner text-neutral-50 text-xl font-semibold w-full lg:w-fit"
              >
                <Link to="/signup">{t("play_now")}</Link>
              </Button>
            </div>
            <div className="flex justify-right">
              <img
                className="w-[958.93px] relative origin-top-left rotate-2 flex-col justify-start items-start flex"
                src="./macbook-mockup-full.webp"
                alt="mockup of GTA being played on a MacBook"
              />
            </div>
          </div>

          <div className="flex-col justify-start items-center gap-8 inline-flex pt-[96px] w-full">
            <div className="self-stretch flex-col justify-start items-center text-center gap-5 flex">
              <div className="self-stretch flex-col justify-start items-start text-center gap-3 flex">
                <div className="self-stretch text-center text-muted-foreground text-base font-semibold leading-normal">
                  {t("why_gameaway")}
                </div>
                <h1 className="text-neutral-50 text-6xl font-bold tracking-tight pb-[24px] w-full">
                  {t("your_device_your_games")}
                  <br />
                  {t("our_power")}
                </h1>
              </div>
              <p className="text-muted-foreground mt-4 pb-[72px]">
                {t("experience_pc_gaming")}
              </p>
            </div>
          </div>

          <div className="w-full">
            <div className="lg:h-[400px] h-fit w-full px-8 lg:py-[48px] justify-start gap-24 flex flex-col lg:flex-row">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-8 inline-flex">
                <div className="self-stretch h-40 flex-col justify-start items-start gap-5 flex">
                  <div className="w-14 h-14 p-3.5 shadow-2xl shadow-fuchsia-700 rounded-full justify-center items-center inline-flex">
                    <div className="w-7 h-7 relative flex-col justify-start items-start flex">
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="message-chat-circle">
                          <path
                            id="Icon"
                            d="M7.11007 13.1002C7.03756 12.6328 6.99993 12.1541 6.99993 11.6666C6.99993 6.51193 11.2061 2.33325 16.3947 2.33325C21.5832 2.33325 25.7894 6.51193 25.7894 11.6666C25.7894 12.831 25.5748 13.9456 25.1827 14.9735C25.1012 15.187 25.0605 15.2937 25.042 15.3771C25.0237 15.4596 25.0166 15.5177 25.0146 15.6023C25.0126 15.6876 25.0242 15.7816 25.0473 15.9696L25.517 19.7849C25.5679 20.1979 25.5933 20.4044 25.5246 20.5545C25.4644 20.686 25.3575 20.7905 25.2246 20.8477C25.0729 20.9129 24.867 20.8827 24.4553 20.8224L20.7392 20.2776C20.5452 20.2492 20.4481 20.235 20.3598 20.2355C20.2723 20.236 20.2119 20.2424 20.1263 20.2604C20.0399 20.2786 19.9294 20.32 19.7085 20.4027C18.6779 20.7887 17.5611 20.9999 16.3947 20.9999C15.9068 20.9999 15.4276 20.963 14.9597 20.8917M8.90349 25.6666C12.3625 25.6666 15.1666 22.7937 15.1666 19.2499C15.1666 15.7061 12.3625 12.8333 8.90349 12.8333C5.44444 12.8333 2.64033 15.7061 2.64033 19.2499C2.64033 19.9623 2.75363 20.6475 2.96279 21.2878C3.0512 21.5584 3.0954 21.6937 3.10991 21.7862C3.12506 21.8827 3.12771 21.9369 3.12207 22.0344C3.11667 22.1278 3.0933 22.2334 3.04655 22.4446L2.33331 25.6666L5.82725 25.1894C6.01796 25.1634 6.11331 25.1503 6.19658 25.1509C6.28425 25.1515 6.33079 25.1563 6.41677 25.1734C6.49843 25.1897 6.61983 25.2325 6.86263 25.3182C7.50234 25.544 8.18894 25.6666 8.90349 25.6666Z"
                            stroke="#06B6D4"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="self-stretch h-[84px] flex-col justify-start items-start gap-4 flex">
                    <div className="self-stretch text-neutral-50 text-2xl font-semibold leading-10">
                      {t("works_basic_devices")}
                    </div>
                    <div className="self-stretch text-[#94969c] text-lg font-normal leading-7">
                      {t("play_high_end_games")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex sm:shrink-0 justify-right rounded-xl">
                <img
                  className="rounded-[32px] relative flex-col justify-start items-start flex"
                  src="./cloud-gaming-1.webp"
                  alt="cloud gaming on a laptop"
                />
              </div>
            </div>

            <div className="lg:h-[400px] h-fit w-full px-8 py-[48px] justify-start gap-24 flex flex-col lg:flex-row">
              <div className="grow shrink basis-0 flex-col justify-right items-start gap-8 lg:hidden flex">
                <div className="self-stretch h-40 flex-col justify-start items-start gap-5 flex">
                  <div className="w-14 h-14 p-3.5 shadow-2xl shadow-fuchsia-700 rounded-full justify-center items-center inline-flex">
                    <div className="w-7 h-7 relative flex-col justify-start items-start flex">
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="zap-fast">
                          <path
                            id="Icon"
                            d="M10.5 20.4167H4.08333M7.58333 14H2.33333M10.5 7.58333H4.66666M19.8333 3.5L12.1375 14.2742C11.7969 14.7511 11.6266 14.9895 11.6339 15.1883C11.6403 15.3614 11.7233 15.5226 11.8604 15.6285C12.0179 15.75 12.3109 15.75 12.897 15.75H18.6667L17.5 24.5L25.1958 13.7258C25.5365 13.2489 25.7068 13.0105 25.6994 12.8117C25.693 12.6386 25.61 12.4774 25.4729 12.3715C25.3154 12.25 25.0224 12.25 24.4364 12.25H18.6667L19.8333 3.5Z"
                            stroke="#06B6D4"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="self-stretch h-[84px] flex-col justify-start items-start gap-4 flex">
                    <div className="self-stretch text-neutral-50 text-2xl font-semibold leading-10">
                      {t("low_internet_usage")}
                    </div>
                    <div className="self-stretch text-[#94969c] text-lg font-normal leading-7">
                      {t("optimised_stable_gaming")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-left sm:shrink-0 rounded-xl">
                <img
                  className="rounded-[32px] relative flex-col justify-start items-start flex"
                  src="./cloud-gaming-2.webp"
                  alt="cloud gaming on a laptop"
                />
              </div>
              <div className="grow shrink basis-0 flex-col justify-right items-start gap-8 hidden lg:inline-flex">
                <div className="self-stretch h-40 flex-col justify-start items-start gap-5 flex">
                  <div className="w-14 h-14 p-3.5 shadow-2xl shadow-fuchsia-700 rounded-full justify-center items-center inline-flex">
                    <div className="w-7 h-7 relative flex-col justify-start items-start flex">
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="zap-fast">
                          <path
                            id="Icon"
                            d="M10.5 20.4167H4.08333M7.58333 14H2.33333M10.5 7.58333H4.66666M19.8333 3.5L12.1375 14.2742C11.7969 14.7511 11.6266 14.9895 11.6339 15.1883C11.6403 15.3614 11.7233 15.5226 11.8604 15.6285C12.0179 15.75 12.3109 15.75 12.897 15.75H18.6667L17.5 24.5L25.1958 13.7258C25.5365 13.2489 25.7068 13.0105 25.6994 12.8117C25.693 12.6386 25.61 12.4774 25.4729 12.3715C25.3154 12.25 25.0224 12.25 24.4364 12.25H18.6667L19.8333 3.5Z"
                            stroke="#06B6D4"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="self-stretch h-[84px] flex-col justify-start items-start gap-4 flex">
                    <div className="self-stretch text-neutral-50 text-2xl font-semibold leading-10">
                      {t("low_internet_usage")}
                    </div>
                    <div className="self-stretch text-[#94969c] text-lg font-normal leading-7">
                      {t("optimised_stable_gaming")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="h-fit lg:h-[400px] w-full px-8 py-[48px] justify-start gap-24 flex flex-col lg:flex-row">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-8 inline-flex">
                <div className="self-stretch h-40 flex-col justify-start items-start gap-5 flex">
                  <div className="w-14 h-14 p-3.5 shadow-2xl shadow-fuchsia-700 rounded-full justify-center items-center inline-flex">
                    <div className="w-7 h-7 relative flex-col justify-start items-start flex">
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="chart-breakout-square">
                          <path
                            id="Icon"
                            d="M12.8333 3.50004H9.1C7.13982 3.50004 6.15972 3.50004 5.41103 3.88152C4.75247 4.21707 4.21703 4.75251 3.88148 5.41107C3.5 6.15977 3.5 7.13986 3.5 9.10004V18.9C3.5 20.8602 3.5 21.8403 3.88148 22.589C4.21703 23.2476 4.75247 23.783 5.41103 24.1186C6.15972 24.5 7.13982 24.5 9.1 24.5H18.9C20.8602 24.5 21.8403 24.5 22.589 24.1186C23.2475 23.783 23.783 23.2476 24.1185 22.589C24.5 21.8403 24.5 20.8602 24.5 18.9V15.1667M14 9.33337H18.6667V14M18.0833 4.08337V2.33337M22.6792 5.32081L23.9167 4.08337M23.9286 9.91671H25.6786M3.5 15.5717C4.2606 15.6891 5.03985 15.75 5.83333 15.75C10.9508 15.75 15.4762 13.2156 18.223 9.33337"
                            stroke="#06B6D4"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="self-stretch h-[84px] flex-col justify-start items-start gap-4 flex">
                    <div className="self-stretch text-neutral-50 text-2xl font-semibold leading-10">
                      {t("pay_when_play")}
                    </div>
                    <div className="self-stretch text-[#94969c] text-lg font-normal leading-7">
                      {t("no_upfront_costs")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-right sm:shrink-0 rounded-xl">
                <img
                  className="rounded-[32px] relative flex-col justify-start items-start flex"
                  src="./cloud-gaming-3.webp"
                  alt="cloud gaming on a laptop"
                />
              </div>
            </div>
          </div>

          <div className="h-fit lg:h-[583px] w-full pt-20 pb-28 bg-[#7f7f7f]/5 rounded-[32px] shadow-lg shadow-cyan-500 flex-col justify-start items-start gap-16 inline-flex">
            <div className="self-stretch h-fit lg:h-[173px] px-8 flex-col justify-start items-start gap-8 flex">
              <div className="self-stretch h-fit lg:h-[173px] flex-col justify-start items-center gap-8 flex">
                <div className="self-stretch text-center text-[#94969c] text-base font-semibold leading-normal">
                  {t("how_it_works")}
                </div>
                <div className="self-stretch lg:h-[117px] flex-col justify-start items-center gap-5 flex">
                  <div className="self-stretch text-center text-neutral-50 text-[56px] font-extrabold leading-[67.20px]">
                    {t("three_simple_steps")}
                  </div>
                  <div className="self-stretch text-center text-[#94969c] text-xl font-normal leading-[30px]">
                    {t("effortless_setup")}
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch h-fit lg:h-[154px] px-8 flex-col justify-start items-start flex">
              <div className="self-stretch justify-center items-start lg:inline-flex lg:flex-row flex flex-col gap-6">
                <div className="grow shrink basis-0 px-8 lg:flex-col justify-start w-full items-center gap-5 inline-flex">
                  <div className="w-12 h-12 p-[9px] bg-cyan-600 rounded-full shadow border-2 border-cyan-500 justify-center items-center inline-flex">
                    <div className="w-[30px] text-center text-neutral-50 text-xl font-semibold leading-[30px]">
                      1
                    </div>
                  </div>
                  <div className="self-stretch min-h-[86px] flex-col justify-center w-full lg:justify-start items-center gap-2 flex">
                    <div className="self-stretch lg:text-center text-neutral-50 text-xl font-semibold leading-[30px]">
                      {t("how_it_works_1")}
                    </div>
                    <div className="self-stretch lg:text-center text-[#94969c] text-base font-normal leading-normal">
                      {t("how_it_works_2")}
                    </div>
                  </div>
                </div>
                <div className="grow shrink basis-0 px-8 lg:flex-col justify-start w-full items-center gap-5 inline-flex">
                  <div className="w-12 h-12 p-[9px] bg-cyan-600 rounded-full shadow border-2 border-cyan-500 justify-center items-center inline-flex">
                    <div className="w-[30px] text-center text-neutral-50 text-xl font-semibold leading-[30px]">
                      2
                    </div>
                  </div>
                  <div className="self-stretch min-h-[86px] flex-col justify-center w-full lg:justify-start items-center gap-2 flex">
                    <div className="self-stretch lg:text-center text-neutral-50 text-xl font-semibold leading-[30px]">
                      {t("how_it_works_3")}
                    </div>
                    <div className="self-stretch lg:text-center text-[#94969c] text-base font-normal leading-normal">
                      {t("how_it_works_4")}
                    </div>
                  </div>
                </div>
                <div className="grow shrink basis-0 px-8 lg:flex-col justify-start items-center gap-5 inline-flex">
                  <div className="w-12 h-12 p-[9px] bg-cyan-600 rounded-full shadow border-2 border-cyan-500 justify-center items-center inline-flex">
                    <div className="w-[30px] text-center text-neutral-50 text-xl font-semibold leading-[30px]">
                      3
                    </div>
                  </div>
                  <div className="self-stretch min-h-[86px] flex-col justify-center w-full lg:justify-start items-center gap-2 flex">
                    <div className="self-stretch lg:text-center text-neutral-50 text-xl font-semibold leading-[30px]">
                      {t("how_it_works_5")}
                    </div>
                    <div className="self-stretch lg:text-center text-[#94969c] text-base font-normal leading-normal">
                      {t("how_it_works_6")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container mx-auto px-4 pt-[96px]">
            <div>
              <h1 className="text-neutral-50 text-6xl font-bold tracking-tight">
                {t("works_any_device")}
              </h1>
              <p className="text-muted-foreground mt-4 pb-[16px]">
                {t("play_high_end_games")}
              </p>
              <Button
                variant="default"
                className="h-14 px-8 py-2 rounded-full shadow shadow-inner text-neutral-50 text-xl font-semibold"
              >
                <Link to="/signup">{t("play_now")}</Link>
              </Button>
            </div>
            <div className="lg:hidden">
              <img
                src="./cloud-gaming-devices.png"
                alt="cloud gaming on multiple device types"
              />
            </div>
            <div className="relative top-[-60px] hidden lg:block">
              <img
                className="relative object-right-top"
                src="./cloud-gaming-devices.png"
                alt="cloud gaming on multiple device types"
              />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(LandingPage);
